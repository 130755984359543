.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-left: -32px;
}

.masonry-column {
  background-clip: padding-box;
  padding-left: 32px;
}

.masonry-column > div {
  margin-bottom: 1rem;
}
