/* WebKit scrollbar styles */
::-webkit-scrollbar {
  -webkit-appearance: none;
  border-radius: 8px;
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid rgb(200, 200, 200);
  border-radius: 6px;
  background-color: rgb(100, 100, 100);
}

::-webkit-scrollbar-track {
  border: 1px solid rgb(200, 200, 200);
  border-radius: 8px;
  background-color: rgb(180, 180, 180);
}

/* Firefox-specific scrollbar styling using CSS hack */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgb(100, 100, 100) rgb(180, 180, 180);
  }
}
